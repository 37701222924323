// This module is a replacement for the `sicky.ts` module - it extracts out JUST the concept of an
// .is-stuck CSS class which is applied when the element is stuck. It requires some level of
// coordination in CSS: the element should have a `position: sticky` style, and the
// inset-block-start _must_ be set to -1px or less. This allows the element to scroll ever so slightly
// out of viewport, which means the intersection observer can kick in and apply the .position-stuck
// class without introducing cycles (as in, an infinite loop of applying/unapplying the class).

// Once all callsites relying on sticky.ts are refactoring to using this pattern, we should deprecate
// the sticky.ts module and remove it from the build.
import {observe} from '@github/selector-observer'

const intersectionObserver = new IntersectionObserver(
  records => {
    for (const record of records) {
      const target = record.target
      target.classList.toggle('position-stuck', record.intersectionRatio < 1)
      if (record.intersectionRatio < 1 && target instanceof HTMLElement) {
        const doc = target.ownerDocument.documentElement
        const height = record.intersectionRect.height
        doc.style.setProperty('--sticky-is-stuck-calculated-height', `${height}px`)
        // Applying the "is-stuck" class can cause the element to change height,
        // which means we need to re-calculate the height of the element and
        // update the CSS variable.
        requestAnimationFrame(() => {
          const newHeight = target.getBoundingClientRect().height
          if (height !== newHeight) {
            doc.style.setProperty('--sticky-is-stuck-calculated-height', `${newHeight}px`)
          }
        })
      }
    }
  },
  {threshold: 1},
)

observe('.js-sticky-is-stuck', {
  constructor: HTMLElement,
  add(el) {
    intersectionObserver.observe(el)
  },
})
